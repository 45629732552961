<template>
  <div class="container">
    <div class="header">
      <div class="header-name">
        <img class="avator" src="../../assets/menu_01.png" alt="">
        <span class="regret">早安，ZhangSan，祝你开心每一天！</span>
      </div>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="cl-one">
          <div class="clo-left common">
            <div>
              <div class="clol-name">账户余额</div>
              <div class="extra">￥9,485.54</div>
              <div class="clol-ky"><span class="span">可用余额</span><span>￥9,485.54</span></div>
              <div class="clol-ky"><span class="span">不可用余额</span><span>￥9,485.54</span></div>
            </div>
            <div><a-button type="primary">充值</a-button></div>
          </div>
          <div class="clo-right common">
            <div class="clor-name">代办事项</div>
            <div class="clor-ct">
              <div class="colr-blc">
                <div class="clor-title">待开票金额</div>
                <h1 class="clor-account">852.00</h1>
              </div>
              <div class="colr-blc">
                <div class="clor-title">待支付订单</div>
                <h1 class="clor-account clo-red">0</h1>
              </div>
              <div class="colr-blc">
                <div class="clor-title">待提交订单</div>
                <h1 class="clor-account">0</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="cl-two common">
          <h2 class="h2">我的导航</h2>
          <ul class="clt-ul">
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
            <li><a href="https://www.baidu.com" target="_blink">交易订单</a></li>
          </ul>
        </div>
        <div class="cl-three common">
          <h2 class="h2">业务概览</h2>
          <div class="mychart">
            <div id="myChart"></div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="cr-one common">
          <h2 class="h2">交易数据</h2>
          <ul class="cro-ul">
            <li>
              <p class="crou-title">充值总金额</p>
              <div class="crou-count">415,412</div>
            </li>
            <li>
              <p class="crou-title">今日充值</p>
              <div class="crou-count">415,412</div>
            </li>
            <li>
              <p class="crou-title">累计支付金额</p>
              <div class="crou-count">415,412</div>
            </li>
            <li>
              <p class="crou-title">累计支付订单(笔)</p>
              <div class="crou-count">58</div>
            </li>
          </ul>
        </div>
        <div class="cr-two common">
          <ul class="crt-o">
            <li>商户信息</li>
            <li>商户简称：测试科技</li>
            <li>预留联系人：张中瑞 </li>
            <li>预留电话：15881025920</li>
            <li>预留联系人：张中瑞 </li>
          </ul>
          <div class="line"></div>
          <ul class="crt-o">
            <li>智赋云结算公众号</li>
            <li>关注智赋云获取最新动态<br/>随时随地与智赋云高效管理</li>
          </ul>
          <div>
            <img style="margin-top:20px;width:117px;height:127px" src="../../assets/home_erwei.png" alt="">
          </div>
        </div>
        <div class="cr-three common">
          <div class="crt-header">
            <span class="crth-l">系统公告</span>
            <span class="crth-r">查看全部</span>
          </div>
          <ul class="crt-ul">
            <li><a href="https://www.baidu.com" target="_blink">【公告】节省计划正式发布</a></li>
            <li><a href="https://www.baidu.com" target="_blink">【公告】2020年5月16日01:00-08:00阿里云交易系统升级通知</a></li>
            <li><a href="https://www.baidu.com" target="_blink">【升级】账单明细增加新字段</a></li>
            <li><a href="https://www.baidu.com" target="_blink">【升级】财务单元支持所有产品可分账</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {
    this.drawLine()
  },
  methods: {
    drawLine(){
      console.log(this.$echarts)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      let color = [
          "#8B5CFF",
          "#00CA69"
      ];

      let xAxisData = ["1", "2", "3", "4", "5", "6", "7", "8"];
      let yAxisData1 = [100, 138, 350, 173, 180, 150, 180, 230];
      let yAxisData2 = [233, 233, 200, 180, 199, 233, 210, 180];

      const hexToRgba = (hex, opacity) => {
          let rgbaColor = "";
          let reg = /^#[\da-f]{6}$/i;
          if (reg.test(hex)) {
              rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
            "0x" + hex.slice(3, 5)
          )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
          }
          return rgbaColor;
      }

      let option = {
          backgroundColor: '#fff',
          color: color,
          legend: {
            top: 20,
            
          },
          tooltip: {
              trigger: "axis",
              formatter: function(params) {
                  let html = '';
                  params.forEach(v => {
                      html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${color[v.componentIndex]};"></span>
                      ${v.seriesName}2020.${v.name}  
                      <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px;margin-left:5px">${v.value}</span>
                      万元`;
                  })
                  return html
              },
              extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
              axisPointer: {
                  type: 'shadow',
                  shadowStyle: {
                      color: '#ffffff',
                      shadowColor: 'rgba(225,225,225,1)',
                      shadowBlur: 5
                  }
              }
          },
          grid: {
              top: 100,
              containLabel: true
          },
          xAxis: [{
              type: "category",
              boundaryGap: false,
              axisLabel: {
                  formatter: '2020-{value}',
                  textStyle: {
                      color: "#333"
                  }
              },
              axisLine: {
                  lineStyle: {
                      color: "#D9D9D9"
                  }
              },
              data: xAxisData,
          }],
          yAxis: [{
              type: "value",
              name: '单位（万元）',
              axisLabel: {
                  textStyle: {
                      color: "#666"
                  }
              },
              nameTextStyle: {
                  color: "#666",
                  fontSize: 12,
                  lineHeight: 40
              },
              // 分割线
              splitLine: {
                  lineStyle: {
                      type: "dashed",
                      color: "#E9E9E9"
                  }
              },
              axisLine: {
                  show: false
              },
              axisTick: {
                  show: false
              }
          }],
          series: [{
              // name: "2018",
              name: "支付总金额",
              type: "line",
              smooth: true,
              symbolSize: 8,
              zlevel: 3,
              lineStyle: {
                  normal: {
                      color: color[0],
                      shadowBlur: 3,
                      shadowColor: hexToRgba(color[0], 0.5),
                      shadowOffsetY: 8
                  }
              },
              symbol: 'circle',//数据交叉点样式
              areaStyle: {
                  normal: {
                      color: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [{
                                  offset: 0,
                                  color: hexToRgba(color[0], 0.3)
                              },
                              {
                                  offset: 1,
                                  color: hexToRgba(color[0], 0.1)
                              }
                          ],
                          false
                      ),
                      shadowColor: hexToRgba(color[0], 0.1),
                      shadowBlur: 10
                  }
              },
              data: yAxisData1
          }, {
              name: "充值总金额",
              type: "line",
              smooth: true,
              symbolSize: 8,
              zlevel: 3,
              lineStyle: {
                  normal: {
                      color: color[1],
                      shadowBlur: 3,
                      shadowColor: hexToRgba(color[1], 0.5),
                      shadowOffsetY: 8
                  }
              },
              symbol: 'circle',//数据交叉点样式 (实心点)
              areaStyle: {
                  normal: {
                      color: new this.$echarts.graphic.LinearGradient(
                          0,
                          0,
                          0,
                          1,
                          [{
                                  offset: 0,
                                  color: hexToRgba(color[1], 0.3)
                              },
                              {
                                  offset: 1,
                                  color: hexToRgba(color[1], 0.1)
                              }
                          ],
                          false
                      ),
                      shadowColor: hexToRgba(color[1], 0.1),
                      shadowBlur: 10
                  }
              },
              data: yAxisData2
          }]
      };
      myChart.setOption(option);
    }
  }
}
</script>
<style scoped lang="less">
.mychart{
  width: 100%;
  overflow-x: overlay;
}
#myChart{
  width: 100%;
  height: 400px;
}
.header{
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 200px;
  background: #FFFFFF url(../../assets/home_bg.png) no-repeat;
  background-size: contain;
  .header-name{
    display: flex;
    align-items: center;
    padding: 35px 0 0 23px;
    width: 685px;
    .avator{
      width: 62px;
      height: 62px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .regret{
      color: #333333;
      font-size: 20px;
    }
  }
}
.common{
  background: #ffffff;
  border: 1px solid #E3E4E6;
  border-radius: 4px;
}
.content{
  position: relative;
  top: -80px;
  padding: 0px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  // padding-bottom: 30px;
  .content-left{
    width: 66.3%;
    .cl-one{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .clo-left{
        width: 49.3%;
        height: 200px;
        background: #FFFFFF url(../../assets/home_ct.png) no-repeat top right;
        background-size: 50px 40px;
        padding: 28px 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #737373;
        .extra{
          font-size: 28px;
          color: #347CF5;
          margin: 15px 0;
        }
        .clol-name{
          font-size: 18px;
          color: #333333;
        }
        .clol-ky{
          margin-bottom: 6px;
          .span{
            display: inline-block;
            width: 70px;
            margin-right: 10px;
          }
        }
      }
      .clo-right{
        width: 49.3%;
        height: 200px;
        padding: 28px 36px;
        .clor-name{
          font-size: 18px;
          color: #333333;
          margin-bottom: 19px;
        }
        .clor-ct{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .colr-blc{
            width: 164px;
            // height: 83px;
            background: #F7F9FA;
            border-radius: 2px;
            padding: 7px 24px;
            .clor-title{
              font-size: 14px;
              color: #737373;
              margin-bottom: 5px;
            }
            .clor-account{
              font-size: 28px;
              color: #333333;
              margin: 0;
            }
            .clo-red{
              color: #DF1313;
            }
          }
        }
      }
    }
    .cl-two{
      margin: 19px 0;
      padding: 28px 36px 28px 7px;
      .h2{
        font-size: 18px;
        color: #333333;
        padding-left: 29px;
        margin-bottom: 0;
      }
      .clt-ul{
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        li{
          margin: 26px 0 0 29px;
          a{
            display: inline-block;
            width: 169px;
            background: #F4F6F8;
            border-radius: 2px;
            padding: 6px 7px;
            font-size: 14px;
            color: #707070;
          }
        }
      }
    }
    .cl-three{
      padding: 28px 36px;
      padding-bottom: 0;
      .h2{
        font-size: 18px;
        color: #333333;
        margin-bottom: 0;
      }
    }
  }
  .content-right{
    width: 32.7%;
    .cr-one{
      padding: 28px 36px;
      .h2{
        font-size: 18px;
        color: #333333;
      }
      .cro-ul{
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        li{
          width: 260px;
          background: #F7F9FA;
          padding: 7px 24px;
          margin-top: 10px;
          .crou-title{
            color: #737373;
            font-size: 14px;
            margin-bottom: 20px;
          }
          .crou-count{
            font-size: 28px;
            color: #333333;
          }
        }
      }
    }
    .cr-two{
      padding: 28px 35px;
      margin: 19px 0 26px 0;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .crt-o{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          font-size: 14px;
          color: #707070;
          margin: 0 0 15px 0;
          &:first-child{
            font-size: 18px;
            color: #333333;
          }
        }
      }
      .line{
        width: 1px;
        height: 166px;
        border-left: 1px dashed #707070;
        opacity: 1;
      }
    }
    .cr-three{
      padding: 27px 24px;
      .crt-header{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 18px;
        color: #333333;
        .crth-r{
          font-size: 12px;
          color: #347CF5;
          cursor: pointer;
        }
      }
      .crt-ul{
        padding: 0;
        margin: 0;
        list-style: none;
        li{
          margin: 15px 0 0 0;
          a{
            font-size: 14px;
            color: #707070;
          }
        }
      }
    }
  }
}
</style>